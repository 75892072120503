import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup, CircularProgress } from '@mui/material';
import moment from 'moment';
import { PAGINATION_OPTIONS } from '../constants';
import subscriptionsApi from '../api/subscriptions.api';
import CSVFileViewer from './CSVFileViewer';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>File Logs</h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const isUK = process.env.REACT_APP_DEFAULT_REGION === 'UK';

const FILE_TYPE = {
  ALL: 'ALL',
  OFFER_FILE: 'OFFER_FILE',
  RECURRING_PAYMENTS_FILE: 'RECURRING_PAYMENTS_FILE',
  PAYMENT_RESPONSE: 'PAYMENT_RESPONSE',
  OFFER_REMINDER: 'OFFER_REMINDER',
};

function SubscriptionsFileLogs({ viewHeight = '30vh' }) {
  const [tableColumns, setTableColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterFileType, setFilterFileType] = useState(FILE_TYPE.ALL);

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const fetchReport = async () => {
    let filterValue = {};
    if (filterFileType !== FILE_TYPE.ALL) {
      filterValue = { file_type: { eq: filterFileType } };
    }
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: JSON.stringify(filterValue),
    };
    setIsLoading(true);

    return subscriptionsApi.getFileLogs(dispatch, params);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  function updateColumns() {
    const columns = [];
    columns.push({
      headerName: 'Date',
      renderCell: (c) =>
        moment(c.value).format(process.env.REACT_APP_DATE_TIME_FORMAT),
      field: 'createdAt',
      width: 160,
      disableColumnMenu: true,
    });
    columns.push({
      headerName: 'File Name',
      render: (e) => e.fileName,
      field: 'fileName',
      headerClassName: 'custom-dark-theme--header',
      width: 360,
      disableColumnMenu: true,
    });
    columns.push({
      headerName: 'File Type',
      render: (e) => e.fileType,
      field: 'fileType',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
      disableColumnMenu: true,
    });
    columns.push({
      headerName: 'Status',
      render: (e) => e.processStatus,
      field: 'processStatus',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
      disableColumnMenu: true,
    });
    columns.push({
      headerName: 'Total',
      renderCell: (c) => `${c.value !== null ? c.value : ''}`,
      field: 'totalNoOfRecords',
      headerClassName: 'custom-dark-theme--header',
      width: 70,
      disableColumnMenu: true,
    });
    columns.push({
      headerName: 'Valid',
      rrenderCell: (c) => `${c.value !== null ? c.value : ''}`,
      field: 'totalValidRecords',
      headerClassName: 'custom-dark-theme--header',
      width: 70,
      disableColumnMenu: true,
    });
    columns.push({
      headerName: 'Failed',
      renderCell: (c) => `${c.value !== null ? c.value : ''}`,
      field: 'totalFailedRecords',
      headerClassName: 'custom-dark-theme--header',
      width: 70,
      disableColumnMenu: true,
    });
    columns.push({
      headerName: 'File Path',
      renderCell: (params) => {
        return (
          <div>
            <CSVFileViewer
              title={params.row.fileName}
              path={params.row.filePath}
            />
          </div>
        );
      },
      field: 'filePath',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
      disableColumnMenu: true,
    });
    if (
      filterFileType === FILE_TYPE.ALL ||
      filterFileType === FILE_TYPE.OFFER_FILE
    ) {
      columns.push({
        headerName: 'Notified',
        render: (e) => e.notificationSent,
        field: 'notificationSent',
        headerClassName: 'custom-dark-theme--header',
        width: 120,
        disableColumnMenu: true,
      });
      columns.push({
        headerName: 'Failed File Path',
        renderCell: (params) => {
          return (
            <div>
              <CSVFileViewer
                title={params.row.failedPath}
                path={params.row.failedPath}
              />
            </div>
          );
        },
        field: 'failedPath',
        headerClassName: 'custom-dark-theme--header',
        width: 120,
        disableColumnMenu: true,
      });
    }
    if (filterFileType !== FILE_TYPE.OFFER_FILE) {
      columns.push({
        headerName: 'Response File Path',
        renderCell: (params) => {
          return (
            <div>
              <CSVFileViewer
                title={params.row.responseFilePath}
                path={params.row.responseFilePath}
              />
            </div>
          );
        },
        field: 'responseFilePath',
        headerClassName: 'custom-dark-theme--header',
        width: 120,
        disableColumnMenu: true,
      });
    }
    if (
      !isUK &&
      (filterFileType === FILE_TYPE.ALL ||
        filterFileType === FILE_TYPE.OFFER_FILE)
    ) {
      columns.push({
        headerName: 'SFMC File Path',
        renderCell: (params) => {
          return (
            <div>
              <CSVFileViewer
                title={params.row.sfmcFilePath}
                path={params.row.sfmcFilePath}
              />
            </div>
          );
        },
        field: 'sfmcFilePath',
        headerClassName: 'custom-dark-theme--header',
        width: 120,
        disableColumnMenu: true,
      });
    }
    columns.push({
      headerName: 'Metadata',
      render: (e) => e.metaData,
      field: 'metaData',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
      disableColumnMenu: true,
    });
    setTableColumns(columns);
  }

  function selectTab(type) {
    setDataSource([]);
    setRowCount(0);
    setFilterFileType(type);
  }

  function getTabVariant(type) {
    if (filterFileType === type) {
      return 'contained';
    }
    return 'outlined';
  }

  useEffect(() => {
    const loadData = async () => {
      updateColumns();
      const reports = await fetchReport();
      if (reports && reports.data) {
        updateDataSource(reports.data.data);
        setRowCount(reports.data.totalItems);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, filterFileType]);

  return (
    <div>
      <div style={{ marginBottom: 5 }} className="d-flex">
        <div>
          <ButtonGroup disableElevation variant="outlined" color="primary">
            <Button
              variant={getTabVariant(FILE_TYPE.ALL)}
              onClick={() => selectTab(FILE_TYPE.ALL)}
            >
              ALL
            </Button>
            <Button
              variant={getTabVariant(FILE_TYPE.OFFER_FILE)}
              onClick={() => selectTab(FILE_TYPE.OFFER_FILE)}
            >
              OFFERS
            </Button>
            <Button
              variant={getTabVariant(FILE_TYPE.RECURRING_PAYMENTS_FILE)}
              onClick={() => selectTab(FILE_TYPE.RECURRING_PAYMENTS_FILE)}
            >
              RECURRING
            </Button>
            <Button
              style={{ display: isUK ? 'none' : '' }}
              variant={getTabVariant(FILE_TYPE.PAYMENT_RESPONSE)}
              onClick={() => selectTab(FILE_TYPE.PAYMENT_RESPONSE)}
            >
              PAYMENT RESPONSE
            </Button>
            <Button
              variant={getTabVariant(FILE_TYPE.OFFER_REMINDER)}
              onClick={() => selectTab(FILE_TYPE.OFFER_REMINDER)}
            >
              REMINDERS
            </Button>
          </ButtonGroup>
        </div>
        <div className="flex-grow-1" />
      </div>
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={tableColumns}
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default SubscriptionsFileLogs;
